import LanguageIcon from '@mui/icons-material/Language';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import ListIcon from '@mui/icons-material/List';
import './navbar.scss'
import React from 'react';

const Navbar = () => {
    return (
        <div className='navbar'>
            <div className="wrapper">
                    <div className="search">
                    </div>
                    <div className="items">
                        <div className="item">
                            <LanguageIcon className="icon" /> 
                            English
                        </div>
                        <div className="item">
                            <DarkModeIcon className="icon" />
                        </div>
                        <div className="item">
                            <FullscreenExitIcon className="icon" />
                        </div>
                        <div className="item">
                            <NotificationsNoneIcon className="icon" />
                            <div className="counter">1</div>
                        </div>
                        <div className="item">
                            <ChatBubbleIcon className="icon" />
                            <div className="counter">2</div>
                        </div>
                        <div className="item">
                            <ListIcon className="icon" />
                        </div>
                        <div className="item">
                            <img
                                src="https://images.pexels.com/photos/941693/pexels-photo-941693.jpeg?auto=compress&cs=tinysrgb&dpr=2&w=500"
                                alt=""
                                className="avatar"
                            />
                        </div>
                    </div>
                </div>
        </div>
    )
}

export default Navbar